import 'styles/main.css';
import 'styles/chrome-bug.css';
import { useEffect, useState, useContext, useRef } from 'react';
import React from 'react';
import { appWithTranslation } from 'next-i18next';
import Layout from 'components/Layout';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';

import { MyUserContextProvider } from 'utils/useUser';

import { UserProvider } from '../contexts/UserContext';
import { FeedProvider } from '../contexts/FeedContext';
import { ImageProvider, useImage } from '../contexts/ImageContext';
import { RenderProvider } from '../contexts/RenderContext';
import { ProjectProvider } from '../contexts/ProjectContext';
import AppContextProvider from '../components/hooks/context';
import CacheContext from '../contexts/CacheContext';

import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { useRouter } from 'next/router';
import Script from 'next/script';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
// import Feed from 'components/Feed';
export async function getStaticProps({ locale }) {
  console.log('loading translation for', locale);

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'menus'])) // 'menus' is used for component translation and common for page translation itself.
    }
  };
}
// import Feed from 'components/Feed';

import { useTranslation, Trans } from 'next-i18next';

const MyApp = ({ Component, pageProps }) => {
  const [supabaseClient] = useState(() => createBrowserSupabaseClient());
  const router = useRouter();
  const { t } = useTranslation('common');
  const [clicks, setClicks] = useState(null);
  const [image, setImage] = useState(null);
  const [maskImg, setMaskImg] = useState(null);
  const [maskLockedImgs, setMaskLockedImgs] = useState([]);
  const [maskLockedCanvasImgs, setMaskLockedCanvasImgs] = useState([]);
  const [brushMode, setBrushMode] = useState('simple');

  const cache = useRef({});
  const cacheKeys = useRef([]);

  const contextValue = {
    clicks: [clicks, setClicks],
    image: [image, setImage],
    maskImg: [maskImg, setMaskImg],
    maskLockedImgs: [maskLockedImgs, setMaskLockedImgs],
    maskLockedCanvasImgs: [maskLockedCanvasImgs, setMaskLockedCanvasImgs],
    brushMode: [brushMode, setBrushMode]
  };

  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  const gtmID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;

  const tagManagerArgs = {
    gtmId: gtmID
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="">
      <link
        href="https://fonts.cdnfonts.com/css/hanno-test"
        rel="stylesheet"
      ></link>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
		window.dataLayer = window.dataLayer || [];
	  function gtag(){dataLayer.push(arguments);}
	  gtag('js', new Date());
	  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
	    page_path: window.location.pathname,
	  });
	`}
      </Script>
      <Script>
        {
          '(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,"rewardful");'
        }
      </Script>
      <Script src="https://r.wdfl.co/rw.js" data-rewardful="663fa4"></Script>
      <Script
        strategy="lazyOnload"
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
      ></Script>

      <SessionContextProvider supabaseClient={supabaseClient}>
        <MyUserContextProvider>
          <UserProvider>
            <FeedProvider>
              <ImageProvider>
                <RenderProvider>
                  <ProjectProvider>
                    <Layout translator={t}>
                      <CacheContext.Provider value={{ cache, cacheKeys }}>
                        <AppContextProvider value={contextValue}>
                          <Component {...pageProps} />
                        </AppContextProvider>
                      </CacheContext.Provider>
                    </Layout>
                  </ProjectProvider>
                </RenderProvider>
              </ImageProvider>
            </FeedProvider>
          </UserProvider>
        </MyUserContextProvider>
      </SessionContextProvider>
    </div>
  );
};

export default appWithTranslation(MyApp);
